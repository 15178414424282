$modalWidth: 669px;

.materialModal {
  margin: 0;
}

.modal {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: hidden;

  &.fullHeight {
    height: 100vh;
  }

  .header {
    padding: var(--size-sizing-x-small) 72px var(--size-sizing-xx-small) var(--size-sizing-x-small);

    .titleWithActions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .title {
    flex: 1;
    margin: 0;
    font-size: var(--font-size-font-size-6);
    line-height: var(--line-height-heading);
    color: var(--color-dominant-on-dominant);
    font-family: var(--font-family-heading);
    white-space: pre-wrap;
  }

  .subTitle {
    margin: var(--size-sizing-xxx-small) 0 0 1px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-dominant-tertiary);
    white-space: pre-wrap;
  }

  .body {
    position: relative;
    display: flex;
    flex-grow: 2;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      padding: var(--size-sizing-xxx-small) var(--size-sizing-x-small);
      overflow: hidden;
      z-index: 0;

      >* {
        max-width: 100%;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: var(--color-dominant-tertiary)
      }
    }

    &.noScroll {
      .body {
        overflow: hidden;

        .content {
          height: initial;
        }
      }
    }
  }

  .footer,
  .modalAction {
    display: flex;
    flex: 0 0 100%;
    flex-basis: auto;
    align-items: center;
    padding: var(--size-sizing-xx-small) var(--size-sizing-x-small) var(--size-sizing-x-small) var(--size-sizing-x-small);

    .footerActions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      button:not(:first-child) {
        margin-left: var(--spacing-spacing-xxx-small);
      }

      .checkbox,
      .tertiary {
        flex-grow: 2;
      }

      .tertiary {
        &.isAlignRight {
          flex-grow: initial;
        }
      }
    }
  }

  .modalAction {
    padding: var(--size-sizing-xx-small) 0 0 var(--size-sizing-x-small);
  }

  .secondaryButton {
    margin-right: var(--size-sizing-xxx-small);
  }

  .closeIcon {
    position: absolute;
    right: 20px;
    top: 21.5px;
    z-index: 999;
  }

  &.noScroll {
    .body {
      overflow: hidden;

      .content {
        overflow-y: hidden;
        height: auto;
      }
    }
  }

  &.noTitle {
    .body {
      margin-top: var(--spacing-spacing-xxx-small);
    }
  }

  &.noAction {
    .body {
      margin-bottom: var(--spacing-spacing-xxx-small);
    }
  }

  // For SideContentModal
  &.sideContent {
    .content {
      padding: var(--spacing-spacing-x-small) !important;
      min-width: 480px;
    }

    .closeIcon {
      top: 18px;
    }

    .header {
      padding: 0;
    }

    .body {
      margin: 0;
    }
  }
}

@media only screen and (min-width: 640px) {
  .modal {
    &.default {
      min-width: 100%;
    }

    .header {
      word-break: break-word;
    }

    .body {
      .content {
        padding: var(--spacing-spacing-xx-small) var(--spacing-spacing-x-small);
      }
    }

    &.auto {
      min-width: initial;
    }
  }
}

.dialog {
  :global(.MuiDialog-paperWidthSm) {
    max-width: $modalWidth !important;
    width: $modalWidth !important;
  }

  .paper {
    border-radius: var(--border-radius-large);
  }
  @media only screen and (max-width: 480px) {
    .paper {
      margin: 0;
      height: 100%;
      width: 100% !important;
      max-height: 100%;
      border-radius: 0 !important;
      padding-top: var(--size-sizing-xx-large);
    }
    .header {
      padding: var(--size-sizing-x-small);
    }
    .footer {
      padding: var(--size-sizing-xx-small);
      border-top: 1px solid #E6E6E6;
      .footerActions {
        justify-content: space-between;
        button {
          width: 100%;
        }
      }
    }
  }

  :global(.MuiDialog-root) {
    z-index: 600000;
  }

  &.small {
    >div>div {
      width: 512px !important;
      max-width: 512px !important;
    }
  }

  &.large {
    >div>div {
      width: 800px !important;
      max-width: 800px !important;
    }
  }

  &.x-large {
    >div>div {
      width: 960px !important;
      max-width: 960px !important;
    }
  }

  &.big {
    >div>div {
      width: 1078px !important;
      max-width: 1078px !important;
    }
  }
}
